.normal-nav {
  margin: 4px 0;
  font-size: 12px;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.3em;
  padding-left: 10px;
  /* border-bottom: 0.4px solid grey; */
  display: flex;
  flex-direction: row;
  height: 38px;
  border-left: 4px solid #2e3345;
  color: #b4b6bd;
  transition-duration: 300ms;
}

.normal-nav:hover {
  color: #ffffff;
  background-color: #252939;
  border-left: 4px solid #528ff0;
}

.active-nav {
  background-color: #252939;
  border-left: 4px solid #528ff0;
  color: #ffffff;
}

.tabbar-normal-nav {
  margin: 4px 0;
  font-size: 12px;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.3em;
  /* border-bottom: 0.4px solid grey; */
  display: flex;
  flex-direction: row;
  height: 38px;
  border-bottom: 3px solid #2e3345;
  color: #b4b6bd;
  transition-duration: 300ms;
}

.tabbar-normal-nav:hover {
  color: #ffffff;
  background-color: #ffffff;
  border-bottom: 3px solid #528ff0;
}

.tabbar-active-nav {
  background-color: #ffffff;
  border-bottom: 3px solid #528ff0;
  color: #ffffff;
}

/* .sidebar-responsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
} */

/* @media */
