/* .head-left {
  width: 25%;
}
.head-right {
  width: 75%;
  flex-direction: row;
  display: flex;
  background-color: #4e3d53;
} */
.dashboard-body {
  display: flex;
  width: "100%";
  height: "100vh";
  box-sizing: border-box;
}

.body-left h3 {
  padding: 10px;
}
.page-left {
  background-color: palegreen;
  width: 25%;
  min-height: 80vh;
}

.page-right {
  background-color: pink;
  width: 75%;
}
